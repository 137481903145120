import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FieldError } from 'react-hook-form';
import { useInputField } from '$hooks';
import {
    StyledInputFieldWrapper,
    StyledInputField,
    StyledLabel,
    StyledInput,
    StyledInvalidMessage,
    StyledHelpText,
    StyledWrapper,
    StyledSvgIcon,
    StyledOuterLabel,
    StyledOuterSubLabel,
    StyledTextArea,
} from './style';

type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export type InputFieldProps = InputProps & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Errors from react-hook-form
     */
    error?: FieldError;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    spacingBottom?: 'extrasmall' | 'small' | 'medium' | 'large';

    /**
     * Should icon be displayed in right side of input
     */
    withIcon?: boolean;

    /**
     * Using normal onChange event messes with the validation, so use this onChange for additional onChange handling
     */
    onChangeSideEffect?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

    /**
     * Function on blur event
     */
    onBlurSideEffect?: () => void;

    /**
     * Place label above input instead of inside
     */
    isOuterLabel?: boolean;

    /**
     * An extra label places under main label for extra info
     */
    outerSubLabel?: string;

    /**
     * Toggle between input or textArea
     */
    inputType?: 'input' | 'textArea';

    /**
     * Util prop to allow for styling the container with emotion.
     */
    className?: string;

    /**
     * Switch to app style for input colors
     */
    appStyle?: boolean;
};

export const InputField = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputFieldProps>(
    (
        {
            className,
            label,
            helpText,
            id,
            children,
            isActive,
            error,
            spacingBottom,
            withIcon,
            readOnly,
            isOuterLabel,
            outerSubLabel,
            inputType = 'input',
            onChangeSideEffect,
            onChange,
            onBlurSideEffect,
            appStyle,
            ...rest
        },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasValue, setHasValue] = useState(!!(rest.value || rest.defaultValue));
        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const theme = useTheme();

        const { fieldId, labelId, helpTextId, invalidMessageId, describedById, showHelpText } = useInputField({
            id,
            helpText,
        });

        const isInputActive = ((isActive || hasFocus) && !readOnly) || !!hasValue;
        const isValid = !error && isValidityValid;
        const isInvalid = !!error;

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
            onBlurSideEffect?.();
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (inputRef.current !== event.target) {
                inputRef.current?.focus();
                inputRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setHasValue(!!event.target.value);
            onChange?.(event);
            onChangeSideEffect?.(event);
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement | HTMLTextAreaElement);

        useEffect(() => {
            !!rest.defaultValue && setHasValue(true);
            !!rest.value && setHasValue(true);
        }, [rest.defaultValue, rest.value]);

        return (
            <StyledWrapper spacingBottom={spacingBottom} className={className}>
                <StyledInputFieldWrapper
                    key={fieldId}
                    isInvalid={isInvalid}
                    onFocus={onFocusHandler}
                    onBlur={onBlueHandler}
                    isValid={isValid}
                >
                    {!!label && isOuterLabel && (
                        <StyledOuterLabel
                            id={labelId}
                            htmlFor={fieldId}
                            isInvalid={isInvalid}
                            title={label}
                            isActive={isInputActive}
                            isValid={isValid}
                            hasFocus={hasFocus}
                        >
                            {label}
                        </StyledOuterLabel>
                    )}
                    {!!outerSubLabel && isOuterLabel && (
                        <StyledOuterSubLabel
                            id={labelId}
                            htmlFor={fieldId}
                            isInvalid={isInvalid}
                            title={outerSubLabel}
                            isActive={isInputActive}
                            isValid={isValid}
                            hasFocus={hasFocus}
                        >
                            {outerSubLabel}
                        </StyledOuterSubLabel>
                    )}
                    <StyledInputField
                        onMouseDown={onInputFieldClick}
                        ref={inputFieldRef}
                        noFixedHeight={inputType === 'textArea'}
                    >
                        {!!label && !isOuterLabel && (
                            <StyledLabel
                                id={labelId}
                                htmlFor={fieldId}
                                isInvalid={isInvalid}
                                title={label}
                                isActive={isInputActive}
                                isValid={isValid}
                                hasFocus={hasFocus}
                                withOuterLabel={isOuterLabel}
                                readOnly={readOnly || false}
                                appStyle={appStyle}
                            >
                                {label}
                            </StyledLabel>
                        )}

                        {inputType === 'input' && (
                            <StyledInput
                                withLabel={!!label}
                                withOuterLabel={isOuterLabel}
                                isActive={isInputActive}
                                isInvalid={isInvalid}
                                id={fieldId}
                                aria-describedby={describedById}
                                readOnly={readOnly}
                                ref={inputRef as React.RefObject<HTMLInputElement>}
                                onChange={handleOnChange}
                                appStyle={appStyle}
                                {...rest}
                            />
                        )}
                        {inputType === 'textArea' && (
                            <StyledTextArea
                                withLabel={!!label}
                                withOuterLabel={isOuterLabel}
                                isActive={isInputActive}
                                isInvalid={isInvalid}
                                id={fieldId}
                                aria-describedby={describedById}
                                ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                                onChange={handleOnChange}
                                appStyle={appStyle}
                                {...rest}
                            />
                        )}
                        {withIcon && (!!hasValue || !!error) && (
                            <StyledSvgIcon
                                size={18}
                                svg={error ? 'cross' : 'checkmark'}
                                isError={!!error}
                                color={error ? theme.colors.red : theme.colors.checkoutGreen}
                            />
                        )}
                    </StyledInputField>
                    {children}
                </StyledInputFieldWrapper>
                {error && <StyledInvalidMessage id={invalidMessageId}>{error.message}</StyledInvalidMessage>}
                {showHelpText && <StyledHelpText id={helpTextId}>{helpText}</StyledHelpText>}
            </StyledWrapper>
        );
    }
);

export default InputField;
