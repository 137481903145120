import React, { FC, memo, useState } from 'react';
import styled from '@emotion/styled';
import { default as NextImage, ImageLoaderProps, ImageProps } from 'next/image';
import { switchProp } from 'styled-tools';
import { motion } from 'framer-motion';
import { toStringParams } from '$lib/helpers/to-string-params.helper';
import { getImageId, getImageUrl, IMAGE_ID_URL } from '$lib/helpers';

export type ImageMode = 'max' | 'contain';

export type ImageAnchor = 'center';

export enum PaddingTypes {
    extraSmall = 'extraSmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
}

/**
 * The @param src can be either a full image string or a resourceId
 */
type Props = ImageProps & {
    paddingType?: keyof typeof PaddingTypes;
    placeholder?: boolean;
    mode?: ImageMode;
    anchor?: ImageAnchor;
    height?: number;
    width?: number;
    scale?: boolean;
    priority?: boolean;
    className?: string;
    src?: string;
    absoluteSrc?: string;
    resourceID?: string;
    blendMode?: 'darken' | 'multiply';
    blendColor?: string;
};

type ImageScalerParams = {
    src: string;
    w?: number;
    h?: number;
    mode?: ImageMode;
    anchor?: ImageAnchor;
};

const animationVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const Image: FC<Props> = memo(({ ...props }) => {
    const {
        alt,
        height,
        width,
        paddingType,
        placeholder,
        className,
        anchor,
        mode = 'max',
        scale,
        priority,
        resourceID,
        src,
        absoluteSrc,
        blendMode,
        blendColor,
        ...restProps
    } = props;
    const [visibility, setVisibility] = useState<keyof typeof animationVariants>(priority ? 'visible' : 'hidden');

    const getDimension = (dimension?: number): number | undefined => {
        if (width && height) {
            return dimension;
        }
    };

    const getSrc = ({ src, w, h }: ImageScalerParams) => {
        // If image has a resouce ID we'll use that to fetch the image
        if (resourceID) {
            return `${IMAGE_ID_URL}/${resourceID}/webp/${h || 0}/${w || 0}/${resourceID}.webp`;
        }

        // We'll then attempt to fetch id from the image url
        const imageId = getImageId(src);
        if (imageId) {
            return `${IMAGE_ID_URL}/${imageId}/webp/${h || 0}/${w || 0}/${imageId}.webp`;
        }

        // If no Id is found we fallback to old image logic
        const imageParams = toStringParams({ w, h, mode, anchor });
        const querySeparator = src.includes('?') ? '&' : '?';
        return `${getImageUrl(src)}${imageParams ? querySeparator + imageParams : ''}`;
    };

    const loader = ({ src, width }: ImageLoaderProps): string => absoluteSrc || getSrc({ src, w: width });

    return (
        <ImageContainer
            className={className}
            placeholder={placeholder}
            height={scale ? undefined : height}
            width={scale ? undefined : width}
            paddingType={paddingType}
        >
            <ImageInnerContainer
                variants={animationVariants}
                animate={visibility}
                transition={{ ease: 'easeOut', duration: 0.25 }}
                blendMode={blendMode}
            >
                <StyledImage
                    key={resourceID || src?.split(' ').join('')}
                    loader={loader}
                    height={getDimension(height)}
                    width={getDimension(width)}
                    layout={scale ? 'responsive' : width && height ? undefined : 'fill'}
                    objectFit={props.objectFit ?? 'contain'}
                    alt={alt}
                    onLoadingComplete={() => setVisibility('visible')}
                    priority={priority}
                    src={absoluteSrc || src?.split(' ').join('') || ''}
                    {...restProps}
                />
            </ImageInnerContainer>
        </ImageContainer>
    );
});

const StyledImage = styled(NextImage)<{ width?: number; height?: number }>(({ width, height }) => ({
    height: height ? height + 'px' : 'auto',
    width: width ? width + 'px' : '100%',
    maxWidth: '100%',
    margin: 'auto',
}));

const ImageContainer = styled.div<{
    height?: number;
    width?: number | 'auto';
    paddingType?: keyof typeof PaddingTypes;
    placeholder?: boolean;
}>(
    ({ theme, height, width, placeholder }) => ({
        display: 'flex',
        borderRadius: theme.general.borderRadius,
        height: height ? `${height}px` : '100%',
        width: width ? `${width}px` : '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        backgroundColor: placeholder ? theme.colors.imgBg : 'initial',
    }),
    switchProp('paddingType', {
        [PaddingTypes.extraSmall]: ({ theme }) => ({
            padding: `${theme.space[3]} ${theme.space[2]}`,
        }),
        [PaddingTypes.small]: ({ theme }) => ({
            padding: `${theme.space[4]} ${theme.space[3]}`,
        }),
        [PaddingTypes.medium]: ({ theme }) => ({
            padding: `${theme.space[5]} ${theme.space[4]}`,
        }),
        [PaddingTypes.large]: ({ theme }) => ({
            padding: `${theme.space[6]} ${theme.space[5]}`,
        }),
    })
);

export const ImageInnerContainer = styled(motion.div)<{ blendMode?: 'darken' | 'multiply'; blendColor?: string }>(
    ({ blendMode, blendColor, theme }) => ({
        position: 'relative',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '& > *': {
            flex: 1,
        },
        ...(blendMode && {
            backgroundColor: blendColor || theme.colors.imgBg,
            '& img': {
                mixBlendMode: `${blendMode}`,
            },
        }),
    })
);
