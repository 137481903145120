import { FC } from 'react';
import styled from '@emotion/styled';
import { ICalculatedBasket, IShippingType } from '$models';
import { Flex } from '$components/layouts';
import { AppVoucher } from '../app-voucher';

// Fixed basket info component for app checkout

type Props = { basket?: ICalculatedBasket; shipping?: IShippingType; onVoucherFocus: (focus: boolean) => void };

export const AppBasketBottomContent: FC<Props> = ({ basket, shipping, onVoucherFocus }) => {
    return (
        <InfoContainer column flex>
            <AppVoucher basket={basket} shipping={shipping} onVoucherFocus={onVoucherFocus} />
        </InfoContainer>
    );
};

const InfoContainer = styled(Flex)(() => ({}));
