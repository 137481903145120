import { ICalculatedBasket, IIShippingType } from '../../models';

export const findShippingType = (basket: ICalculatedBasket | undefined): IIShippingType | undefined => {
    const shippingKey = findShippingTypeByKey(basket);

    if (shippingKey && shippingKey.active) {
        return shippingKey;
    } else {
        const activeShippingTypes = basket?.shippingTypes?.filter((x) => x.active && !x.hidden);
        const defaultShipping = activeShippingTypes?.find((x) => x.isDefault);

        if (defaultShipping) return defaultShipping;
        return activeShippingTypes ? activeShippingTypes[0] : undefined;
    }
};

const findShippingTypeByKey = (basket: ICalculatedBasket | undefined) => {
    return basket?.shippingTypes?.find((shippingType) => shippingType.shippingKey === basket?.shippingKey);
};
