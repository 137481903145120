const generateKey = (): ((obj: Record<string, any>) => string) => {
    const map = new WeakMap();
    let index = 0;

    /**
     * Generates unique key from objects,
     * can be used when no unique key is present on dataset
     */
    const weakKey = (obj: Record<string, any>): string => {
        let key = map.get(obj);
        if (!key) {
            key = `generated-key-${index++}`;
            map.set(obj, key);
        }
        return JSON.stringify(key);
    };

    return weakKey;
};

export const key = generateKey();
