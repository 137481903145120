import styled from '@emotion/styled';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { mq } from '$lib/helpers';
import { SvgIcon } from '../svg-icon';

type ValidationProps = {
    isInvalid: boolean;
    isValid: boolean;
};

type StyledInputProps = {
    isActive: boolean;
    isInvalid: boolean;
    withLabel: boolean;
    withOuterLabel?: boolean;
} & AppStyleProps;

type StyledLabelProps = {
    hasFocus: boolean;
    isActive: boolean;
    isInvalid: boolean;
    isValid: boolean;
    readOnly?: boolean;
    withOuterLabel?: boolean;
} & AppStyleProps;

type StyledWrapperProps = {
    spacingBottom?: 'extrasmall' | 'small' | 'medium' | 'large';
};

type StyledInputFieldWrapperProps = ValidationProps;

type StyledSelectProps = { hasValue: boolean } & ValidationProps & AppStyleProps;

type AppStyleProps = { appStyle?: boolean };

export const StyledWrapper = styled.div<StyledWrapperProps>(
    {
        position: 'relative',
    },
    switchProp('spacingBottom', {
        extrasmall: ({ theme: { space } }) => ({
            marginBottom: space[3],
        }),
        small: ({ theme: { space } }) => ({
            marginBottom: space[5],
        }),
        medium: ({ theme: { space } }) => ({
            marginBottom: space[7],
        }),
        large: ({ theme: { space } }) => ({
            marginBottom: space[9],
        }),
    })
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>({});

export const StyledInputField = styled.div<{ noFixedHeight?: boolean }>(({ theme, noFixedHeight }) => ({
    height: noFixedHeight ? 'auto' : '50px',
    lineHeight: theme.lineHeights.single,
    position: 'relative',
    width: '100%',
    cursor: 'type',
}));

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: theme.space[4],
        fontSize: theme.fontSizes.sm,
        transition: '0.1s transform, 0.1s width',
        transform: 'translateY(-50%)',
        width: `100%`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
        lineHeight: theme.lineHeights.base,
        zIndex: 2,
    }),
    ifNotProp('isInvalid', ({ theme, hasFocus, appStyle }) => ({
        color: hasFocus ? theme.colors.black : appStyle ? theme.colors.grey40 : theme.colors.grey15,
    })),
    ifProp('isInvalid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('isActive', {
        transform: 'scale(0.75) translateY(-26px)',
        width: '110%',
    }),
    ifProp('readOnly', ({ theme, withOuterLabel }) => ({
        color: withOuterLabel ? theme.colors.black : theme.colors.grey30,
    }))
);

export const StyledOuterLabel = styled.label<StyledLabelProps>(
    ({ theme }) => ({
        lineHeight: theme.lineHeights.base,
        cursor: 'pointer',
        fontSize: theme.fontSizes.sm,
    }),
    ifProp('isInvalid', ({ theme }) => ({
        color: theme.colors.negative,
    }))
);

export const StyledOuterSubLabel = styled(StyledOuterLabel)<StyledLabelProps>({
    display: 'block',
    fontStyle: 'italic',
    opacity: 0.5,
});

export const StyledInput = styled.input<StyledInputProps>(
    ({ theme, appStyle }) => ({
        borderRadius: '8px',
        border: `1px solid ${appStyle ? theme.colors.grey70 : theme.colors.grey30}`,
        display: 'block',
        fontSize: theme.fontSizes.sm,
        outline: 0,
        padding: theme.space[4],
        textAlign: 'left',
        width: '100%',
        height: '50px',
        '&::placeholder': {
            transition: '0.1s color',
            color: theme.colors.grey70,
        },
        '&:hover': {
            borderColor: theme.colors.grey30,
        },

        [mq(0, 'md')]: {
            // important for iOS, otherwise iOS will zoom in everytime you click on the input field
            fontSize: theme.fontSizes.default,
        },
    }),
    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.black,
    })),
    ifProp('readOnly', ({ theme, appStyle }) => ({
        color: theme.colors.black,
        backgroundColor: theme.colors.grey5,
        borderColor: theme.colors.grey10,
        [`&::placeholder`]: {
            color: appStyle ? theme.colors.black : theme.colors.grey30,
        },
        cursor: 'not-allowed',
    })),
    ifProp('isInvalid', ({ theme }) => ({
        borderColor: theme.colors.negative,
    })),
    ifProp('withLabel', ({ theme }) => ({
        padding: `19px ${theme.space[4]} 8px`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        }
    )
);

export const StyledTextArea = styled.textarea<StyledInputProps>(
    ({ theme, appStyle }) => ({
        borderRadius: '8px',
        border: `1px solid ${appStyle ? theme.colors.grey70 : theme.colors.primaryGreyLight}`,
        display: 'block',
        fontSize: theme.fontSizes.sm,
        outline: 0,
        padding: theme.space[4],
        textAlign: 'left',
        width: `100%`,
        height: '300px',
        '&::placeholder': {
            transition: '0.1s color',
            color: appStyle ? theme.colors.grey70 : theme.colors.grey15,
        },
        [mq(0, 'md')]: {
            // important for iOS, otherwise iOS will zoom in everytime you click on the input field
            fontSize: theme.fontSizes.default,
        },
        '&:hover': {
            borderColor: theme.colors.grey30,
        },
    }),
    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.black,
    })),
    ifProp('isInvalid', ({ theme }) => ({
        borderColor: theme.colors.negative,
    })),
    ifProp('withLabel', ({ theme }) => ({
        padding: `19px ${theme.space[4]} 8px`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        }
    ),
    ifProp({ withLabel: true }, ({ theme, appStyle }) => ({
        padding: theme.space[4],
        [`&::placeholder`]: {
            color: appStyle ? theme.colors.black : theme.colors.grey15,
        },
    }))
);

export const StyledHelpText = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes['xxs'],
    color: theme.colors.grey40,
    marginTop: theme.space[1],
}));

export const StyledInvalidMessage = styled(StyledHelpText)(({ theme }) => ({
    color: theme.colors.negative,
    [`+ ${StyledHelpText}`]: {
        display: 'none',
    },
}));

export const StyledSvgIcon = styled(SvgIcon)<{ isError?: boolean }>(({ theme, isError }) => ({
    border: `1px solid ${isError ? theme.colors.secondaryRed : theme.colors.checkoutGreen}`,
    borderRadius: '50%',
    padding: '3px',
    position: 'absolute',
    right: theme.space[2],
    top: '50%',
    transform: 'translateY(-50%)',
}));

export const StyledSelect = styled.select<StyledSelectProps>(
    ({ theme, appStyle }) => ({
        borderRadius: '8px',
        border: `1px solid ${appStyle ? theme.colors.grey70 : theme.colors.primaryGreyLight}`,
        cursor: 'pointer',
        padding: theme.space[3],
        height: '50px',
        appearance: 'none',
        fontSize: theme.fontSizes.sm,
        width: '100%',
        color: appStyle ? theme.colors.black : theme.colors.grey30,
        '&:focus-visible': {
            outline: 'none',
            border: `1px solid ${theme.colors.black}`,
        },
        '& option': {
            color: theme.colors.black,
        },
        '&:hover': {
            borderColor: theme.colors.grey30,
        },
        [mq(0, 'md')]: {
            // important for iOS, otherwise iOS will zoom in everytime you click on the input field
            fontSize: theme.fontSizes.default,
        },
    }),
    ifProp({ disabled: true }, ({ theme, hasValue, appStyle }) => ({
        borderColor: theme.colors.grey10,
        backgroundColor: theme.colors.grey5,
        color: hasValue ? theme.colors.black : appStyle ? theme.colors.black : theme.colors.grey30,
    })),
    ifProp({ isInvalid: true }, ({ theme }) => ({
        borderColor: theme.colors.negative,
        color: theme.colors.negative,
    })),
    ifProp({ isValid: true, hasValue: true }, ({ theme }) => ({
        borderColor: theme.colors.black,
        color: theme.colors.black,
    }))
);
