import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import { mq } from '$lib/helpers';
import { BreakpointKeys } from '$theme';

type Props = {
    min?: number | BreakpointKeys;
    max?: number | BreakpointKeys;
    className?: string;
};

export const Responsive: FC<PropsWithChildren<Props>> = ({ min, max, className, children }) => {
    return (
        <ResponsiveContainer mediaQuery={mq(min, max)} className={className}>
            {children}
        </ResponsiveContainer>
    );
};

type ResponsiveWithNoContentProps = {
    showOnServer?: boolean;
} & Props;

// Reponsive container that removes content from Dom instead of hiding it
export const ResponsiveWithNoContent: FC<PropsWithChildren<ResponsiveWithNoContentProps>> = ({
    min,
    max,
    className,
    children,
}) => {
    const isCurrentMediaQuery = useMedia(mq(min, max, false), false);

    return (
        <>
            {isCurrentMediaQuery && (
                <ResponsiveContainer mediaQuery={mq(min, max)} className={className}>
                    {children}
                </ResponsiveContainer>
            )}
        </>
    );
};

const ResponsiveContainer = styled.div<{ mediaQuery: string }>(({ mediaQuery }) => ({
    '&&': {
        // A little trick to increase specificity to allow for customization.
        display: 'none',

        [mediaQuery]: {
            display: 'inherit',
        },
    },
}));
