import { FC, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { Text } from '$components/elements/text';
import { Button } from '$components/elements/button';
import { formatPrice } from '$lib/helpers';
import { IShippingType } from '$models';
import { useBasket } from '~/store';
import { useTranslation } from '$hooks';
import { B2CBasketType, B2CDeliveryMethod, checkoutPageUrl } from '$constants';
import { findShippingType } from '$templates/basket-page/basket-helper';

// Fixed basket info component for app checkout

type Props = {
    onNotPossibleClick: () => void;
};

export const AppBasketInfo: FC<Props> = ({ onNotPossibleClick }) => {
    const { basket } = useBasket();
    const { translate } = useTranslation();
    const [shippingType, setShippingType] = useState<IShippingType>();
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const theme = useTheme();

    useEffect(() => {
        const foundGiftLines = basket?.lines?.filter((line) => line.isGiftShopItem);
        const foundRegularLines = basket?.lines?.filter((line) => !line.isGiftShopItem);

        if (!!foundGiftLines && !foundRegularLines?.length) {
            setShippingType(undefined);
        } else {
            const foundShipping = findShippingType(basket);

            if (foundShipping) {
                setShippingType(foundShipping);
            }
        }
    }, [basket]);

    const onGoToCheckout = () => {
        setIsLoading(true);
        router.push(checkoutPageUrl);
    };
    const shouldDisplayDefaultShipping = useMemo(() => {
        if (
            basket?.lines?.some((line) => line.b2CDeliveryMethod === B2CDeliveryMethod.DirectOnly) &&
            basket?.lines?.some((line) => line.b2CDeliveryMethod === B2CDeliveryMethod.ShopOnly)
        ) {
            return false;
        }
        return (
            basket?.lines?.filter(
                (line) =>
                    (line.b2CDeliveryMethod === B2CDeliveryMethod.DirectOnly ||
                        line.b2CDeliveryMethod === B2CDeliveryMethod.ShopOnly) &&
                    !line.isGiftShopItem
            ).length === basket?.lines?.length
        );
    }, [basket?.lines]);

    return (
        <InfoContainer>
            <ContentWrapper flex column>
                <TextWrapper justifyContent="space-between">
                    <Text size="12px" dimmed>
                        {!basket?.shippingKey && !shippingType?.downloadOnly ? (
                            <div>{translate('checkout.orderSummary.defaultDeliveryLabel')}</div>
                        ) : (
                            <div>
                                {shouldDisplayDefaultShipping
                                    ? translate('checkout.orderSummary.defaultDeliveryLabel')
                                    : shippingType?.name}
                            </div>
                        )}
                    </Text>
                    <Text size="12px" dimmed>
                        {shippingType?.calculatedShippingPrice === 0
                            ? translate('checkout.checkoutBasket.deliveryFreeText')
                            : formatPrice(shippingType?.calculatedShippingPrice)}
                    </Text>
                </TextWrapper>
                <TextWrapper justifyContent="space-between">
                    <Text fontWeight="Bold">Samlet beløb</Text>
                    <Text fontWeight="Bold">
                        {formatPrice(
                            shippingType
                                ? (basket?.totalIncVAT || 0) + (shippingType?.calculatedShippingPrice || 0)
                                : basket?.totalIncVAT
                        )}
                    </Text>
                </TextWrapper>
                {!!basket?.discount && basket.discount - (basket.voucherDiscount || 0) > 0 && (
                    <TextWrapper justifyContent="space-between">
                        <Text color={theme.colors.primary}>{translate('checkout.orderSummary.yourDiscountLabel')}</Text>
                        <Text color={theme.colors.primary}>
                            {formatPrice(basket.discount - (basket.voucherDiscount || 0))}
                        </Text>
                    </TextWrapper>
                )}
                {basket?.basketType === B2CBasketType.NotPossible ? (
                    <CheckoutButton onClick={onNotPossibleClick} variant="tertiary">
                        {translate('checkout.checkoutBasket.toCheckoutButton')}
                    </CheckoutButton>
                ) : (
                    <CheckoutButton
                        onClick={onGoToCheckout}
                        disabled={!basket?.lines?.length || !!basket.invalidLines?.length}
                        loading={isLoading}
                    >
                        {translate('checkout.checkoutBasket.toCheckoutButton')}
                    </CheckoutButton>
                )}
            </ContentWrapper>
        </InfoContainer>
    );
};

const InfoContainer = styled.div(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.white,
    zIndex: 100,
}));

const ContentWrapper = styled(Flex)({
    boxShadow: '0px -1px 2px 0px rgba(0,0,0,0.2)',
    padding: 20,
});

const TextWrapper = styled(Flex)({
    paddingBottom: 10,
});

const CheckoutButton = styled(Button)({
    marginTop: 5,
    WebkitTapHighlightColor: 'transparent',
});
