import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Flex } from '$components/layouts';
import { brandLeg } from '$constants';
import { BookSpinnerProps } from './bookSpinner';
import { LegeKaedenSpinnerProps } from './legekaedenSpinner';

const DynamicLegeKaedenSpinner = dynamic<LegeKaedenSpinnerProps>(
    () => import('./legekaedenSpinner').then((mod) => mod.LegeKaedenSpinner),
    { ssr: false }
);
const DynamicBookSpinner = dynamic<BookSpinnerProps>(() => import('./bookSpinner').then((mod) => mod.BookSpinner), {
    ssr: false,
});

type Props = {
    showBackdrop?: boolean;
};

export const Loading: FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => (
    <Flex style={{ position: 'relative' }} alignItems="center" justifyContent="center" grow>
        {props.showBackdrop && <Backdrop brandLeg={brandLeg} />}
        {props.children ? props.children : <BrandSpinner center={!!props.showBackdrop} />}
    </Flex>
);

const BrandSpinner: FC<{ center: boolean }> = ({ center }) => {
    if (brandLeg) {
        return <DynamicLegeKaedenSpinner center={center} />;
    }

    return <DynamicBookSpinner center={center} />;
};

const Backdrop = styled.div<{ brandLeg: boolean }>(
    ({ theme }) => ({
        position: 'fixed',
        backgroundColor: theme.colors.black,
        height: '100%',
        opacity: '50%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: theme.zIndices.loadingOverlay,
        width: '100%',
    }),
    ifProp('brandLeg', ({ theme }) => ({
        backgroundColor: theme.colors.white,
    }))
);
