import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useAppStore } from '~/store';
import { Link } from '$components/elements/link';
import { Button } from '$components/elements/button';

type Props = {
    href?: string;
    appClickEvent?: () => void;
    children?: React.ReactNode;
};

export const AppLinkHandler: FC<Props> = ({ href, appClickEvent, children }) => {
    const { isApp } = useAppStore();

    if (isApp) {
        return (
            <AppButton onClick={appClickEvent} variant="custom">
                {children}
            </AppButton>
        );
    }

    return <Link href={href}>{children}</Link>;
};

const AppButton = styled(Button)({
    alignSelf: 'flex-start',
});
