import { FC, Fragment } from 'react';
import { Image } from '$components/elements/image';
import { Flex } from '$components/layouts';
import { IPaymentIPaymentMethod } from '$models';
import { key } from '~/utils';

type Props = {
    paymentIcons: IPaymentIPaymentMethod[];
    iconHeight?: number;
    iconWidth?: number;
    gap?: number;
};

export const PaymentIcons: FC<Props> = ({ paymentIcons, iconHeight, iconWidth, gap }: Props) => {
    if (paymentIcons === undefined || paymentIcons.length === 0) return null;

    const defaultIconSize = 35;
    return (
        <Flex style={{ gap: gap }} gap="small" spacingBottom="small" alignItems="center" wrap="wrap">
            {paymentIcons.map((icon, index) => (
                <Fragment key={`${index}-${icon.name}`}>
                    {!!icon?.iconUrl && (
                        <Image
                            key={key(icon)}
                            height={iconHeight ?? defaultIconSize}
                            width={iconWidth ?? defaultIconSize}
                            src={icon.iconUrl}
                        />
                    )}
                </Fragment>
            ))}
        </Flex>
    );
};
